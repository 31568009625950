"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateBoostPricingLink = exports.checkIsDomainAdmin = void 0;
var checkIsDomainAdmin = exports.checkIsDomainAdmin = function checkIsDomainAdmin() {
  var domain = window.location.hostname;
  return Boolean(domain.match(/^admin.*\.tomocredit\.com$/)) || process.env.REACT_APP_IS_ADMIN;
};
var generateBoostPricingLink = exports.generateBoostPricingLink = function generateBoostPricingLink(queryParameters) {
  return "".concat(process.env.REACT_APP_DOMAIN, "/boost-pricing").concat(queryParameters !== null && queryParameters !== void 0 && queryParameters.size ? "?".concat(queryParameters.toString()) : "");
};